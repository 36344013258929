<template>
    <div>
        <iframe name='iframe_esign' style='display: none;'></iframe>
        <a href='javascript:void(0)' :id='esignId' target='iframe_esign'></a>
    </div>
</template>

<script lang='js'>
// noinspection ES6UnusedImports
import $ from 'jquery/src/jquery'
import * as esignLib from '@/assets/js/esign'
import errorHandler from '@/error/error-handler';
import http from "@/plugins/http";
import {MicroSignerControllerApi} from "@/client";

const ESIGN_ID = 'a_esign';
const MAX_COUNTER = 120;
const POLLING_TIME_INTERVAL = 1000;
let esign;
let globalSessionId;
let callbackOnSuccess;
const microSignerControllerApi = new MicroSignerControllerApi(undefined, process.env.VUE_APP_API_BASE_URL, http);

function showSigningSuccess(readableDocumentIds) {
    errorHandler.showSuccess('announcement.signing.success', {ids: readableDocumentIds})
}

function showSigningError(readableDocumentIds) {
    errorHandler.showError('announcement.signing.failed', {ids: readableDocumentIds})
}

function cancelSession(sessionId, readableDocumentIds) {
    if (sessionId !== globalSessionId) {
        return;
    }
    showSigningError(readableDocumentIds)
    microSignerControllerApi.cancel(sessionId)
    globalSessionId = null;
}

function onSuccessGetCertificate(certificate, sessionId, readableDocumentIds) {
    if (sessionId !== globalSessionId) {
        return;
    }
    microSignerControllerApi.createAndSetUnsignedHash(
        {sessionId: sessionId, certificate: certificate}
    ).then((response) => {
        if (response.status !== 200) {
            cancelSession(sessionId, readableDocumentIds)
        }
    }).catch(() => cancelSession(sessionId, readableDocumentIds))
}

function onSuccessGetSignedHashes(signedHashes, sessionId, readableDocumentIds) {
    if (sessionId !== globalSessionId) {
        return;
    }
    microSignerControllerApi.setSignedHash(
        {sessionId: sessionId, signedHashes: signedHashes}
    ).then((response) => {
        if (response.status === 200) {
            if (response.data.failedToSignDocumentsString) {
                showSigningError(response.data.failedToSignDocumentsString)
            }
            if (response.data.signedDocumentsString) {
                showSigningSuccess(response.data.signedDocumentsString)
                if (callbackOnSuccess) {
                    callbackOnSuccess(sessionId)
                }
                if (sessionId === globalSessionId) {
                    globalSessionId = null;
                }
            }
        } else {
            cancelSession(sessionId, readableDocumentIds)
        }
    }).catch(() => cancelSession(sessionId, readableDocumentIds))
}

export default {
    name: 'MicroSignerComponent',
    mounted() {
        callbackOnSuccess = this.callback;
        microSignerControllerApi.getBaseUrl().then(
            function (baseUrlResponse) {
                esign = new esignLib.ESign({'baseURL': baseUrlResponse.data, 'max_counter': MAX_COUNTER, 'polling_time_interval': POLLING_TIME_INTERVAL});
            }
        )
    },
    data() {
        return {
            esignId: ESIGN_ID,
        }
    },
    beforeDestroy() {
        globalSessionId = null;
    },
    props: ['callback'],
    methods: {
        sign(esignSessionRequest) {
            if (esignSessionRequest.data.request.sessionId) {
                const startSessionPromise = new Promise((resolve) => {
                    if (globalSessionId) {
                        microSignerControllerApi.cancel(globalSessionId);
                        // A pollozás leállítása miatt egy kicsit várunk
                        setTimeout(resolve, 2 * POLLING_TIME_INTERVAL)
                    } else {
                        resolve()
                    }
                });
                globalSessionId = esignSessionRequest.data.request.sessionId
                startSessionPromise.then(() => {
                    esign.selectCertificateAndSign(
                        esignSessionRequest.data.request,
                        ESIGN_ID,
                        (certificate) => onSuccessGetCertificate(certificate, esignSessionRequest.data.request.sessionId, esignSessionRequest.data.readableDocumentIds),
                        (signedHashes) => onSuccessGetSignedHashes(signedHashes, esignSessionRequest.data.request.sessionId, esignSessionRequest.data.readableDocumentIds)
                    )
                })
            } else {
                showSigningError(esignSessionRequest.data.readableDocumentIds)
            }
        }
    }
};
</script>
