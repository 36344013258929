/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

let esign_log = false;
let esign_max_counter = 30;
let esign_polling_time_interval = 1000;

function log(msg) {
    if (esign_log === true) {
        if (typeof console !== 'undefined'
            && typeof console.log !== 'undefined') {
            console.log(msg);
        }
    }
}

function useXDR() {
    log("CORS supported: " + $.support.cors);
    if ($.support.cors) {
        return false;
    }
    var typeOfWindowXDomainRequest = typeof window.XDomainRequest;
    log("type of window.XDomainRequest is " + typeOfWindowXDomainRequest);
    return typeOfWindowXDomainRequest !== 'undefined';
}

function xdrWithSessionIdObj(url, sessionIdObj, onJSONResponse, onNotJSONResponse, onfailure) {
    var xdr = new XDomainRequest();
    xdr.onerror = function () {
        onfailure(null, "error");
    };
    xdr.onload = function () {
        log("responseText: " + xdr.responseText);
        if (xdr.contentType === "application/json") {
            var data = JSON.parse(xdr.responseText);
            onJSONResponse(data);
        }
        else {
            onNotJSONResponse(xdr.contentType, xdr.responseText);
        }
    };
    xdr.ontimeout = function () {
        onfailure(null, "timeout");
    };
    var params = '?sessionId=' + sessionIdObj.sessionId;
    if (typeof sessionIdObj.cancel !== 'undefined') {
        params += '&cancel=' + sessionIdObj.cancel;
    }
    xdr.open('GET', url + params);
    xdr.send();
}

function onHTTPStatus420(jqXHR, textStatus, onESignFailure) {
    log('textStatus: ' + textStatus);
    var statusObj = JSON.parse(jqXHR.responseText);
    onESignFailure(statusObj);
}

function xdrCancel(url, obj, onCanceled, onESignFailure, onfailure) {
    log('xdrCancel param: ' + JSON.stringify(obj));
    xdrWithSessionIdObj(url, obj
        , function(data) {
            switch (data.httpStatusCode) {
                case 200:
                    delete data.httpStatusCode;
                    onCanceled(data);
                    break;
                case 420:
                    delete data.httpStatusCode;
                    onESignFailure(data);
                    break;
                default:
                    onfailure(null, "xdrerror");
            }
        }
        , function (contentType, responseText) {
            log("invalid content: " + contentType);
            onfailure(null, "parseerror");
        }
        , onfailure);
}

function jqCancel(url, obj, onCanceled, onESignFailure, onfailure) {
    var param = JSON.stringify(obj);
    log('jqCancel param: ' + param);
    $.ajax(url, {
        type: 'POST'
        , contentType: 'application/json; charset=UTF-8'
        , data: param
        , dataType: 'json'
        , complete: function (jqXHR, textStatus) {
            log('jqCancel res: ' + jqXHR.responseText);
            switch (jqXHR.status) {
                case 200:
                    var data = JSON.parse(jqXHR.responseText);
                    onCanceled(data);
                    break;
                case 420:
                    onHTTPStatus420(jqXHR, textStatus, onESignFailure);
                    break;
                default:
                    onfailure(jqXHR, textStatus);
            }
        }
    });
}

function cancel(url, obj, onCanceled, onESignFailure, onfailure) {
    if (useXDR()) {
        xdrCancel(url, obj, onCanceled, onESignFailure, onfailure);
    }
    else {
        jqCancel(url, obj, onCanceled, onESignFailure, onfailure);
    }
}

function xdrGetCertificate(esign, onCertificateGot, onCertificateNotGot, counter, onESignFailure, onfailure) {
    log('xdrGetCertificate param: ' + JSON.stringify(esign.sessionIdObj));
    xdrWithSessionIdObj(esign.getCertificateURL, esign.sessionIdObj
        , function(data) {
            switch (data.httpStatusCode) {
                case 200:
                    delete data.httpStatusCode;
                    onCertificateGot(data.certificate);
                    break;
                case 202:
                    if (onCertificateNotGot(++counter)) {
                        setTimeout(function () {
                            xdrGetCertificate(esign, onCertificateGot, onCertificateNotGot, counter, onESignFailure, onfailure);
                        }, esign_polling_time_interval);
                    }
                    else {
                        esign.cancel();
                    }
                    break;
                case 420:
                    delete data.httpStatusCode;
                    onESignFailure(data);
                    break;
                default:
                    onfailure(null, "xdrerror");
            }
        }
        , function (contentType, responseText) {
            log("invalid content: " + contentType);
            onfailure(null, "parseerror");
        }
        , onfailure);
}

function jqGetCertificate(esign, onCertificateGot, onCertificateNotGot, counter, onESignFailure, onfailure) {
    var param = JSON.stringify(esign.sessionIdObj);
    log('jqGetCertificate param: ' + param);
    $.ajax(esign.getCertificateURL, {
        type: 'POST'
        , contentType: 'application/json; charset=UTF-8'
        , data: param
        , dataType: 'json'
        , complete: function (jqXHR, textStatus) {
            log('jqGetCertificate res: ' + jqXHR.responseText);
            switch (jqXHR.status) {
                case 200:
                    var data = JSON.parse(jqXHR.responseText);
                    onCertificateGot(data.certificate);
                    break;
                case 202:
                    if (onCertificateNotGot(++counter)) {
                        setTimeout(function () {
                            jqGetCertificate(esign, onCertificateGot, onCertificateNotGot, counter, onESignFailure, onfailure);
                        }, esign_polling_time_interval);
                    }
                    else {
                        esign.cancel();
                    }
                    break;
                case 420:
                    onHTTPStatus420(jqXHR, textStatus, onESignFailure);
                    break;
                default:
                    onfailure(jqXHR, textStatus);
            }
        }
    });
}

function getCertificate(esign, onCertificateGot, onCertificateNotGot, counter, onESignFailure, onfailure) {

    if (useXDR()) {
        xdrGetCertificate(esign, onCertificateGot, onCertificateNotGot, counter, onESignFailure, onfailure);
    }
    else {
        jqGetCertificate(esign, onCertificateGot, onCertificateNotGot, counter, onESignFailure, onfailure);
    }
}

function xdrGetSignedHashes(esign, onSignedHashesGot, onSignedHashesNotGot, counter, onESignFailure, onfailure) {
    log('xdrGetSignedHashes param: ' + JSON.stringify(esign.sessionIdObj));
    xdrWithSessionIdObj(esign.getSignedHashesURL, esign.sessionIdObj
        , function(data) {
            switch (data.httpStatusCode) {
                case 200:
                    delete data.httpStatusCode;
                    onSignedHashesGot(data.signedHashes);
                    break;
                case 202:
                    if (onSignedHashesNotGot(++counter)) {
                        setTimeout(function () {
                            xdrGetSignedHashes(esign, onSignedHashesGot, onSignedHashesNotGot, counter, onESignFailure, onfailure);
                        }, esign_polling_time_interval);
                    }
                    else {
                        esign.cancel();
                    }
                    break;
                case 420:
                    delete data.httpStatusCode;
                    onESignFailure(data);
                    break;
                default:
                    onfailure(null, "xdrerror");
            }
        }
        , function (contentType, responseText) {
            log("invalid content: " + contentType);
            onfailure(null, "parseerror");
        }
        , onfailure);
}

function jqGetSignedHashes(esign, onSignedHashesGot, onSignedHashesNotGot, counter, onESignFailure, onfailure) {
    var param = JSON.stringify(esign.sessionIdObj);
    log('jqGetSignedHashes param: ' + param);
    $.ajax(esign.getSignedHashesURL, {
        type: 'POST'
        , contentType: 'application/json; charset=UTF-8'
        , data: param
        , dataType: 'json'
        , complete: function (jqXHR, textStatus) {
            log('jqGetSignedHashes res: ' + jqXHR.responseText);
            switch (jqXHR.status) {
                case 200:
                    var data = JSON.parse(jqXHR.responseText);
                    onSignedHashesGot(data.signedHashes);
                    break;
                case 202:
                    if (onSignedHashesNotGot(++counter)) {
                        setTimeout(function () {
                            jqGetSignedHashes(esign, onSignedHashesGot, onSignedHashesNotGot, counter, onESignFailure, onfailure);
                        }, esign_polling_time_interval);
                    }
                    else {
                        esign.cancel();
                    }
                    break;
                case 420:
                    onHTTPStatus420(jqXHR, textStatus, onESignFailure);
                    break;
                default:
                    onfailure(jqXHR, textStatus);
            }
        }
    });
}

function getSignedHashes(esign, onSignedHashesGot, onSignedHashesNotGot, counter, onESignFailure, onfailure) {
    if (useXDR()) {
        xdrGetSignedHashes(esign, onSignedHashesGot, onSignedHashesNotGot, counter, onESignFailure, onfailure);
    }
    else {
        jqGetSignedHashes(esign, onSignedHashesGot, onSignedHashesNotGot, counter, onESignFailure, onfailure);
    }
}

function xdrCheckConfigGot(esign, onConfigGot, onConfigGotParam, onConfigNotGot, counter, onfailure) {
    log('xdrCheckConfigGot param: ' + JSON.stringify(esign.sessionIdObj));
    xdrWithSessionIdObj(esign.checkConfigGotURL, esign.sessionIdObj
        , function(data) {
            if (data === true) {
                onConfigGot(onConfigGotParam);
            }
            else {
                if (onConfigNotGot(++counter)) {
                    setTimeout(function () {
                        xdrCheckConfigGot(esign, onConfigGot, onConfigGotParam, onConfigNotGot, counter, onfailure);
                    }, esign_polling_time_interval);
                }
                else {
                    esign.cancel();
                }
            }
        }
        , function (contentType, responseText) {
            log("invalid content: " + contentType);
            onfailure(null, "parseerror");
        }
        , onfailure);
}

function jqCheckConfigGot(esign, onConfigGot, onConfigGotParam, onConfigNotGot, counter, onfailure) {
    var param = JSON.stringify(esign.sessionIdObj);
    log('jqCheckConfigGot param: ' + param);
    $.ajax(esign.checkConfigGotURL, {
        type: 'POST'
        , contentType: 'application/json; charset=UTF-8'
        , data: param
        , dataType: 'json'
        , success: function (data, textStatus, jqXHR) {
            log('jqCheckConfigGot res: ' + JSON.stringify(data));
            if (data === true) {
                onConfigGot(onConfigGotParam);
            }
            else {
                if (onConfigNotGot(++counter)) {
                    setTimeout(function () {
                        jqCheckConfigGot(esign, onConfigGot, onConfigGotParam, onConfigNotGot, counter, onfailure);
                    }, esign_polling_time_interval);
                }
                else {
                    esign.cancel();
                }
            }
        }
        , error: function (jqXHR, textStatus, errorThrown) {
            onfailure(jqXHR, textStatus);
        }
    });
}

function checkConfigGot(esign, onConfigGot, onConfigGotParam, onConfigNotGot, counter, onfailure) {
    if (useXDR()) {
        xdrCheckConfigGot(esign, onConfigGot, onConfigGotParam, onConfigNotGot, counter, onfailure);
    }
    else {
        jqCheckConfigGot(esign, onConfigGot, onConfigGotParam, onConfigNotGot, counter, onfailure);
    }
}

function onAJAXFailureDefault(jqXHR, textStatus) {
    alert(textStatus);
}

function onESignFailureDefault(status) {
    if (status.code !== 'CANCEL') {
        alert(status.code);
    }
}

function onConfigNotGotDefault(counter) {
    log("onConfigNotGot: " + counter);
    if (counter % esign_max_counter === 0) {
        return confirm("Úgy tűnik, hogy az e-Sign alkalmazás még nem töltődött be. Szeretne tovább várakozni?");
    }
    return true;
}

function onCertificateNotGotDefault(counter) {
    log("onCertificateNotGotDefault: " + counter);
    if (counter % esign_max_counter === 0) {
        return confirm("Úgy tűnik, hogy az alkalmazás még nem kapta meg a kiválasztott tanúsítványt. Szeretne tovább várakozni?");
    }
    return true;
}

function onSignedHashesNotGotDefault(counter) {
    log("onSignedHashesNotGotDefault: " + counter);
    if (counter % esign_max_counter === 0) {
        return confirm("Úgy tűnik, hogy eddig még nem történt meg a dokumentumok aláírása. Szeretne tovább várakozni?");
    }
    return true;
}

export function ESign(settings) {
    if (typeof settings.log === 'boolean') {
        esign_log = settings.log;
    }
    if (typeof settings.max_counter === 'number') {
        esign_max_counter = settings.max_counter;
    }
    if (typeof settings.polling_time_interval === 'number') {
        esign_polling_time_interval = settings.polling_time_interval;
    }
    if (typeof settings.baseURL === 'string') {
        var baseURL = new String(settings.baseURL);
        if (baseURL.charAt(baseURL.length - 1) !== '/') {
            baseURL += '/';
        }
        this.checkConfigGotURL = baseURL + 'checkConfigGot';
        this.getCertificateURL = baseURL + 'getCertificate';
        this.getSignedHashesURL = baseURL + 'getSignedHashes';
        this.getQRCodeURL = baseURL + 'getQRCode';
        this.getDocumentStatesURL = baseURL + 'getDocumentStates';
        this.getClientInfoURL = baseURL + 'getClientInfo';
    }
    if (typeof settings.checkConfigGotURL === 'string') {
        this.checkConfigGotURL = settings.checkConfigGotURL;
    }
    if (typeof settings.getCertificateURL === 'string') {
        this.getCertificateURL = settings.getCertificateURL;
    }
    if (typeof settings.getSignedHashesURL === 'string') {
        this.getSignedHashesURL = settings.getSignedHashesURL;
    }
    if (typeof settings.getQRCodeURL === 'string') {
        this.getQRCodeURL = settings.getQRCodeURL;
    }
    if (typeof settings.getDocumentStatesURL === 'string') {
        this.getDocumentStatesURL = settings.getDocumentStatesURL;
    }
    if (typeof settings.getClientInfoURL === 'string') {
        this.getClientInfoURL = settings.getClientInfoURL;
    }
    this.onCancelAJAXFailure = onAJAXFailureDefault;
    this.onCancelESignFailure = onESignFailureDefault;
    this.onCanceled = function (status) { log('onCanceled res: ' + JSON.stringify(status)); };
    this.onConfigAJAXFailure = onAJAXFailureDefault;
    this.onConfigNotGot = onConfigNotGotDefault;
    this.onConfigGot = function () { };
    this.onCertificateAJAXFailure = onAJAXFailureDefault;
    this.onCertificateESignFailure = onESignFailureDefault;
    this.onCertificateNotGot = onCertificateNotGotDefault;
    this.onSignedHashesAJAXFailure = onAJAXFailureDefault;
    this.onSignedHashesESignFailure = onESignFailureDefault;
    this.onSignedHashesNotGot = onSignedHashesNotGotDefault;

    this.getOnlySignedHashes = function(signedHashes) {
        var onlySignedHashes;
        log('documentIds.length = ' + this.documentIds.length);
        log('signedHashes.length = ' + signedHashes.length);
        if (this.documentIds.length === signedHashes.length) {
            onlySignedHashes = new Array();
            var i;
            for (i = 0; i < signedHashes.length; i++) {
                log('documentIds[' + i + '] = ' + this.documentIds[i]);
                log('signedHashes[' + i + '].documentId = ' + signedHashes[i].documentId);
                if (this.documentIds[i] === signedHashes[i].documentId) {
                    onlySignedHashes[i] = signedHashes[i].signedHash;
                }
                else {
                    log('warning: documentIds[' + i + '] and signedHashes[' + i + '].documentId are not equal');
                    onlySignedHashes[i] = null;
                }
            }
        }
        else {
            log('warning: number of documents and number of signed hashes are not equal');
            onlySignedHashes = null;
        }
        return onlySignedHashes;
    };

    this.start = function (signingSession, object_id) {
        log('window.navigator.appName = ' + window.navigator.appName);
        if (window.navigator.appName === "Microsoft Internet Explorer") {
            log('document.documentMode = ' + document.documentMode);
            if (document.documentMode < 8) {
                log('error: invalid document mode');
                throw new Error("Document mode 8 or higher required using Microsoft Internet Explorer!");
            }
        }
        this.sessionUrl = signingSession.sessionUrl;
        this.sessionIdObj = new Object();
        this.sessionIdObj.sessionId = signingSession.sessionId;
        this.documentIds = signingSession.documentIds;
        var object = document.getElementById(object_id);
        if (typeof object === 'undefined') {
            log('error: no object');
            throw new Error("Illegal argument: no object");
        }
        var scheme = "esign";
        if (object.nodeName === 'A') {
            var tmpSigningSession = new Object();
            tmpSigningSession.sessionUrl = signingSession.sessionUrl;
            tmpSigningSession.sessionId = signingSession.sessionId;
            tmpSigningSession.mode = signingSession.mode;
            object.href = scheme + ":" + encodeURIComponent(JSON.stringify(tmpSigningSession));
            log('a.href = ' + object.href);
            object.click();
        }
        else if (object.nodeName === 'IMG') {
            object.src = this.getQRCodeURL + "?sessionId=" + signingSession.sessionId + "&uriScheme=" + scheme;
            log('img.src = ' + object.src);
        }
        else if (object.nodeName === 'TEXTAREA') {
            var tmpSigningSession = new Object();
            tmpSigningSession.sessionUrl = signingSession.sessionUrl;
            tmpSigningSession.sessionId = signingSession.sessionId;
            tmpSigningSession.mode = signingSession.mode;
            object.value = scheme + ":" + encodeURIComponent(JSON.stringify(tmpSigningSession));
            log('textarea.value = ' + object.value);
            object.select();
            try {
                document.execCommand('copy');
            }
            catch (err) {
                log('error: ' + err);
                throw new Error("Failed to copy esign URI to the clipboard");
            }
        }
        else {
            log('error: invalid object');
            throw new Error("Illegal argument: " + object.nodeName);
        }
    };

    this.cancel = function () {
        this.sessionIdObj.cancel = true;
        cancel(this.sessionUrl, this.sessionIdObj, this.onCanceled, this.onCancelESignFailure, this.onCancelAJAXFailure);
    };

    this.selectCertificateAndSign = function (signingSession, object_id, onSuccessGetCertificate, onSuccessGetSignedHashes) {
        if (signingSession.mode !== 'SELECT_CERTIFICATE_AND_SIGN') {
            throw new Error("Illegal argument: signing session");
        }
        this.start(signingSession, object_id);
        checkConfigGot(this
            , function (onConfigGotParam) {
                onConfigGotParam.onConfigGot();
                getCertificate(onConfigGotParam, function (certificate) {
                        onSuccessGetCertificate(certificate);
                        getSignedHashes(onConfigGotParam, function (signedHashes) {
                                onSuccessGetSignedHashes(onConfigGotParam.getOnlySignedHashes(signedHashes));
                            }
                            , onConfigGotParam.onSignedHashesNotGot
                            , 0
                            , onConfigGotParam.onSignedHashesESignFailure
                            , onConfigGotParam.onSignedHashesAJAXFailure);
                    }
                    , onConfigGotParam.onCertificateNotGot
                    , 0
                    , onConfigGotParam.onCertificateESignFailure
                    , onConfigGotParam.onCertificateAJAXFailure);
            }
            , this
            , this.onConfigNotGot
            , 0
            , this.onConfigAJAXFailure);
    };

    this.selectCertificate = function (signingSession, object_id, onSuccessGetCertificate) {
        if (signingSession.mode !== 'SELECT_CERTIFICATE') {
            throw new Error("Illegal argument: signing session");
        }
        this.start(signingSession, object_id);
        checkConfigGot(this
            , function (onConfigGotParam) {
                onConfigGotParam.onConfigGot();
                getCertificate(onConfigGotParam, function (certificate) {
                        onSuccessGetCertificate(certificate);
                    }
                    , onConfigGotParam.onCertificateNotGot
                    , 0
                    , onConfigGotParam.onCertificateESignFailure
                    , onConfigGotParam.onCertificateAJAXFailure);
            }
            , this
            , this.onConfigNotGot
            , 0
            , this.onConfigAJAXFailure);
    };

    this.sign = function (signingSession, object_id, onSuccessGetSignedHashes) {
        if (signingSession.mode !== 'SIGN') {
            throw new Error("Illegal argument: signing session");
        }
        this.start(signingSession, object_id);
        checkConfigGot(this
            , function (onConfigGotParam) {
                onConfigGotParam.onConfigGot();
                getSignedHashes(onConfigGotParam, function (signedHashes) {
                        onSuccessGetSignedHashes(onConfigGotParam.getOnlySignedHashes(signedHashes));
                    }
                    , onConfigGotParam.onSignedHashesNotGot
                    , 0
                    , onConfigGotParam.onSignedHashesESignFailure
                    , onConfigGotParam.onSignedHashesAJAXFailure);
            }
            , this
            , this.onConfigNotGot
            , 0
            , this.onConfigAJAXFailure);
    };

    var xdrGetDocumentStates = function (url, obj, onsuccess, onfailure) {
        log('xdrGetDocumentStates param: ' + JSON.stringify(obj));
        xdrWithSessionIdObj(url, obj
            , function(data) {
                switch (data.httpStatusCode) {
                    case 200:
                        delete data.httpStatusCode;
                        onsuccess(data.documentStates);
                        break;
                    default:
                        onfailure(null, "xdrerror");
                }
            }
            , function (contentType, responseText) {
                log("invalid content: " + contentType);
                onfailure(null, "parseerror");
            }
            , onfailure);
    };

    var jqGetDocumentStates = function (url, obj, onsuccess, onfailure) {
        var param = JSON.stringify(obj);
        log('jqGetDocumentStates param: ' + param);
        $.ajax(url, {
            type: 'POST'
            , contentType: 'application/json; charset=UTF-8'
            , data: param
            , dataType: 'json'
            , complete: function (jqXHR, textStatus) {
                log('jqGetDocumentStates res: ' + jqXHR.responseText);
                switch (jqXHR.status) {
                    case 200:
                        var data = JSON.parse(jqXHR.responseText);
                        onsuccess(data.documentStates);
                        break;
                    default:
                        onfailure(jqXHR, textStatus);
                }
            }
        });
    };

    this.getDocumentStates = function(onsuccess, onfailure) {
        if (useXDR()) {
            xdrGetDocumentStates(this.getDocumentStatesURL, this.sessionIdObj, onsuccess, onfailure);
        }
        else {
            jqGetDocumentStates(this.getDocumentStatesURL, this.sessionIdObj, onsuccess, onfailure);
        }
    };

    this.test = function (signingSession, object_id) {
        if (signingSession.mode !== 'TEST') {
            throw new Error("Illegal argument: signing session");
        }
        this.start(signingSession, object_id);
        checkConfigGot(this
            , function (onConfigGotParam) {
                onConfigGotParam.onConfigGot();
            }
            , this
            , this.onConfigNotGot
            , 0
            , this.onConfigAJAXFailure);
    };

    var xdrGetClientInfo = function (esign, onClientInfoGot, counter) {
        log('xdrGetClientInfo param: ' + JSON.stringify(esign.sessionIdObj));
        xdrWithSessionIdObj(esign.getClientInfoURL, esign.sessionIdObj
            , function(data) {
                switch (data.httpStatusCode) {
                    case 200:
                        delete data.httpStatusCode;
                        onClientInfoGot(data);
                        break;
                    case 202:
                        if (esign.onClientInfoNotGot(++counter)) {
                            setTimeout(function () {
                                xdrGetClientInfo(esign, onClientInfoGot, counter);
                            }, esign_polling_time_interval);
                        }
                        else {
                            esign.cancel();
                        }
                        break;
                    case 420:
                        delete data.httpStatusCode;
                        esign.onClientInfoESignFailure(data);
                        break;
                    default:
                        esign.onClientInfoAJAXFailure(null, "xdrerror");
                }
            }
            , function (contentType, responseText) {
                log("invalid content: " + contentType);
                esign.onClientInfoAJAXFailure(null, "parseerror");
            }
            , esign.onClientInfoAJAXFailure);
    };

    var jqGetClientInfo = function (esign, onClientInfoGot, counter) {
        var param = JSON.stringify(esign.sessionIdObj);
        log('jqGetClientInfo param: ' + param);
        $.ajax(esign.getClientInfoURL, {
            type: 'POST'
            , contentType: 'application/json; charset=UTF-8'
            , data: param
            , dataType: 'json'
            , complete: function (jqXHR, textStatus) {
                log('jqGetClientInfo res: ' + jqXHR.responseText);
                switch (jqXHR.status) {
                    case 200:
                        var data = JSON.parse(jqXHR.responseText);
                        onClientInfoGot(data);
                        break;
                    case 202:
                        if (esign.onClientInfoNotGot(++counter)) {
                            setTimeout(function () {
                                jqGetClientInfo(esign, onClientInfoGot, counter);
                            }, esign_polling_time_interval);
                        }
                        else {
                            esign.cancel();
                        }
                        break;
                    case 420:
                        onHTTPStatus420(jqXHR, textStatus, esign.onClientInfoESignFailure);
                        break;
                    default:
                        esign.onClientInfoAJAXFailure(jqXHR, textStatus);
                }
            }
        });
    };

    this.onClientInfoAJAXFailure = onAJAXFailureDefault;
    this.onClientInfoESignFailure = onESignFailureDefault;
    this.onClientInfoNotGot = function(counter) {
        log("onClientInfoNotGot: " + counter);
        if (counter % esign_max_counter === 0) {
            return confirm("Úgy tűnik, hogy még nem indult el az aláíró alkalmazás. Szeretne tovább várakozni?");
        }
        return true;
    };
    this.testWithClientInfo = function(signingSession, object_id, onClientInfoGot) {
        if (signingSession.mode !== 'TEST') {
            throw new Error("Illegal argument: signing session");
        }
        this.start(signingSession, object_id);
        if (useXDR()) {
            xdrGetClientInfo(this, onClientInfoGot, 0);
        }
        else {
            jqGetClientInfo(this, onClientInfoGot, 0);
        }
    };
}

export default {
    ESign
}